import { graphql } from "gatsby"
import React from "react"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { SEO } from "../components/Seo"
import { rhythm } from "../utils/typography"

function Homepage(props) {
  // const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <main
      style={{
        margin: "0 auto",
        paddingRight: rhythm(1 / 2),
        paddingLeft: rhythm(1 / 2),
        maxWidth: 600,
      }}
    >
      <h1>Your First Ten Games</h1>
      <blockquote>
        A strategy guide for earning your wings as an indie game developer
      </blockquote>
      <em>
        The Transformation: By the end of this book, you will be equipped with
        the mental framework needed to ship your first ten games successfully.
      </em>

      <h2>Table of contents</h2>

      <h3>Skill 1: Ideation & Requirements</h3>
      <p>...</p>

      <h3>Skill 2: Game Design</h3>
      <p>...</p>

      <h3>Skill 3: Implementation</h3>
      <p>...</p>

      <h3>Skill 4: Playtesting</h3>
      <p>...</p>

      <h3>Skill 5: Productivity & Time Management</h3>
      <p>...</p>
    </main>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentType: { ne: "portfolio" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            description
            previewImageUrl {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default function YourFirstTenGames({ data }) {
  const { site } = data
  // const growthNodes = data.allMdx.edges.map(edge => edge.node)
  // const { fluid } = data.file.childImageSharp
  // console.log('growth nodes:', growthNodes)

  // See gatsby-config.js for actual values.
  const { title, description } = site.siteMetadata

  return (
    <>
      <SEO // This guy has a componentWillMount deprecation.
        description={description}
        imageUrl="https://user-images.githubusercontent.com/914228/73634336-d92bb800-4615-11ea-9739-5982b7a8a02e.png"
        imageAlt={title}
        title=""
      />
      <Header />
      <Homepage />
      <Footer />
    </>
  )
}
